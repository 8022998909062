<template>
  <div id="form-crud-category">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="lastname">{{ $t('common.lastname') }} *</label>
            <input class="form-control" id="lastname" v-model="category.name" type="text">
            <div v-if="errorsForm.name">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="select-pose">{{ $t('common.singlePose') }} *</label>
            <select id="select-pose" class="form-control" v-model="selectPose" @input="changePose">
              <option v-for="(pose, index) in CategoryModule.poses" :key="index" :value="pose.id">
                {{ pose.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>{{ $t('pages.category.tableList.provideQtyRoll') }}</label>

            <div class="ml-5" v-for="(provideNumber, index) in ProvideNumberPerRollsModule.provideNumbersPerRolls" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'provide-number-per-rolls-' + index"
                  :checked="(listFormProvideNumber) ? listFormProvideNumber.includes(provideNumber.id) : false"
                  :value="provideNumber.id"
                  @change="checkboxProvideNumberPerRolls($event, provideNumber.id)"
              />

              <label :for="'provide-number-per-rolls-' + index" class="ml-2">
                {{ (provideNumber.name === 'oui') ? $t('common.yes') : $t('common.no') }}
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="lastname">{{ $t('pages.category.tableList.qtyMax') }} *</label>
            <input class="form-control" id="qty-max" v-model="category.qtyModelMax" type="text">
            <div v-if="errorsForm.qtyModelMax">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.qtyModelMax.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('common.mandrel') }}</label>

            <div class="ml-5" v-for="(mandrel, index) in MandrelModule.mandrels" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'mandrel-' + index"
                  :checked="(listFormMandrel) ? listFormMandrel.includes(mandrel.id) : false"
                  :value="mandrel.id"
                  @change="checkboxMandrel($event, mandrel.id)"
              />

              <label :for="'mandrel-' + index" class="ml-2">{{ mandrel.number }}</label>
            </div>
          </div>

          <div class="form-group">
            <label>Orientation</label>

            <div class="ml-5" v-for="(orientation, index) in OrientationModule.orientations" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'orientation-' + index"
                  :checked="(listFormOrientation) ? listFormOrientation.includes(orientation.id) : false"
                  :value="orientation.id"
                  @change="checkboxOrientation($event, orientation.id)"
              />

              <label :for="'orientation-' + index" class="ml-2">{{ orientation.number }}</label>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('common.paper') }}</label>

            <div class="ml-5" v-for="(paper, index) in PaperModule.papers" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'paper-' + index"
                  :checked="(listFormPaper) ? listFormPaper.includes(paper.id) : false"
                  :value="paper.id"
                  @change="checkboxPaper($event, paper.id)"
              />

              <label :for="'paper-' + index" class="ml-2">{{ paper.name }}</label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('common.picture') }}</label>
          <div>
            <input class="btn btn-dark" type="file" accept="image/png, image/jpg, image/jpeg, image/bmp" @change="handleFileUpload($event)">
          </div>
          <div class="mt-2">
            <img class="w-25" :src="category.picture" />
          </div>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    category: Object,
    id: Number,
    selectedPose: Number,
    listProvideNumberPerRolls: Array,
    listMandrels: Array,
    listOrientations: Array,
    listPapers: Array,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      selectPose: this.selectedPose,
      listFormProvideNumber: [],
      listFormMandrel: [],
      listFormOrientation: [],
      errorsForm: [],
    }
  },
  watch: {
    selectedPose: function() {
      this.selectPose = this.selectedPose
    }
  },
  created: function() {
    this.$store.dispatch('fetchPoses')

    this.$store.dispatch('fetchMandrels', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchOrientations', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchPapers', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchProvideNumbersPerRolls', {
      perPage: null,
      page: null
    })

    // Handle var list
    this.listFormProvideNumber = []
    this.listFormMandrel = []
    this.listFormOrientation = []
    this.listFormPaper = []
  },
  updated() {
    this.listFormProvideNumber = this.listProvideNumberPerRolls
    this.listFormMandrel = this.listMandrels
    this.listFormOrientation = this.listOrientations
    this.listFormPaper = this.listPapers
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- CHECKBOX ------------------*/
    checkboxProvideNumberPerRolls(event, provideNumberPerRollId) {
      if(event.target.checked) {
        this.listFormProvideNumber.push(provideNumberPerRollId)
      } else {
        let indexProvideNumberPerRolls = this.listFormProvideNumber.findIndex(array => array === provideNumberPerRollId)
        if(indexProvideNumberPerRolls > -1) {
          this.listFormProvideNumber.splice(indexProvideNumberPerRolls, 1)
        }
      }
    },
    checkboxMandrel(event, mandrelId) {
      if(event.target.checked) {
        this.listFormMandrel.push(mandrelId)
      } else {
        let indexMandrel = this.listFormMandrel.findIndex(array => array === mandrelId)
        if(indexMandrel > -1) {
          this.listFormMandrel.splice(indexMandrel, 1)
        }
      }
    },
    checkboxOrientation(event, orientationId) {
      if(event.target.checked) {
        this.listFormOrientation.push(orientationId)
      } else {
        let indexOrientation = this.listFormOrientation.findIndex(array => array === orientationId)
        if(indexOrientation > -1) {
          this.listFormOrientation.splice(indexOrientation, 1)
        }
      }
    },
    checkboxPaper(event, paperId) {
      if(event.target.checked) {
        this.listFormPaper.push(paperId)
      } else {
        let indexPaper = this.listFormPaper.findIndex(array => array === paperId)
        if(indexPaper > -1) {
          this.listFormPaper.splice(indexPaper, 1)
        }
      }
    },
    /*----------------- SELECT ------------------*/
    changePose() {
      this.$emit('selectPose',this.category.pose)
    },
    /*----------------- FILES METHOD ------------------*/
    handleFileUpload($event)
    {
      let files = $event.target.files || $event.dataTransfer.files
      this.handleFileToBase64(files[0])
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // Handle var
      this.category.pose = this.selectPose
      this.category.provideNumberPerRolls = this.listFormProvideNumber
      this.category.mandrels = this.listFormMandrel
      this.category.orientations = this.listFormOrientation
      this.category.papers = this.listFormPaper

      // If category (create or update)
      if (this.actionForm === 'edit') {
        this.edit()
      } else {
        this.create()
      }
    },
    /**
     * Create category (submit form)
     */
    create() {

      this.showLoader()

      this.$store.dispatch('createCategory', this.category)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update category (submit form)
     */
    edit() {
      this.showLoader()

      this.$store.dispatch('updateCategory', { id: this.category.id, category: this.category })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to mandrel-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'category-index' })

      // Refresh errors form
      this.errorsForm = []
    },
    /*----------------- PRIVATE METHODS ------------------*/
    handleFileToBase64(file)
    {
      let that = this

      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = function () {
        that.category.picture = reader.result
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.category.editCategory')
      }
      else {
        value = this.$t('pages.category.createCategory')
      }

      return value
    },
    ...mapState(['CategoryModule']),
    ...mapState(['MandrelModule']),
    ...mapState(['OrientationModule']),
    ...mapState(['PaperModule']),
    ...mapState(['ProvideNumberPerRollsModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
